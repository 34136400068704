import React from "react";
import { useSelector } from "react-redux";
import Footer from "../components/CommonSection/LandingPageFooter/Footer";
import Header from "../components/CommonSection/LandingPageHeader/Header";
import { RootState } from "../Store/Store";
interface PropsTypes {
  children: JSX.Element;
}

const LandingPageLayout: React.FC<PropsTypes> = (props): JSX.Element => {
  const loader = useSelector((state: RootState) => state.master.loader);
  return (
    <>
      <Header />
      {loader && (
        <div className="loader-container">
          <div className="spinner" />
        </div>
      )}
      {props.children}
      <Footer />
    </>
  );
};

export default LandingPageLayout;
